button, a {
   // cursor: none !important;
}

.mousefollower {
    position: absolute;
    background: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    z-index: 9999;
    pointer-events: none;
    opacity: .5;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        display:block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #1a1a1a;
    }

    &:before {
        content: ' ';
        position: absolute;
        width: 20px;
        height: 20px;
        background: white;
        z-index:0;
        border-radius: 50%;
        visibility: hidden;
        opacity: 0;
        transition: .6s ease;
    }

    &.hover {
        &:before {
            width: 40px;
            height: 40px;
            opacity: .4;
            visibility: visible;
            transition: .6s ease;
        }
        
    }
}