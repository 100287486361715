.flashing-cursor {
    background: #fff;
    width: 8px;
    height: 8px;
    display: inline-block;
    animation: blink .4s infinite;
    transform: translateX(5px);
}

@keyframes blink {
	0% {opacity: 0}
	49%{opacity: 0}
	50% {opacity: 1}
}