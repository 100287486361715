.navigation {
    padding: 2em;
    position: fixed;
    z-index: 8;
    width: 100%;
    box-sizing: border-box;
    mix-blend-mode: difference;
    user-select: none;
    
    ul {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media only screen and (max-width: $breakpoint) {
            padding: 20px;
        }

        li {
            
            button {
                background: none;
                width: 40px;
                height: 40px;

                svg {
                    fill: white;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        a {
            cursor: pointer;
            color: #fff;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 0.03em;

            svg {
                width: 40px;
            }
            
            &:hover {
                color: #ccc;
            }
        }

        ol {
            display: flex;

            @media only screen and (max-width: $breakpoint) {
                display: none;
            }

            li {
                margin: 0 30px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.mobile-nav {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: black;
    z-index: 4;
    position: fixed;
    transform: translateY((-100%));
    transition: .5s .8s ease-in-out;

    li {
        opacity: 0;
        position: relative;
        top: 10px;
    }

    li:nth-child(1) {
        transition: .3s .1s ease-in-out;
    }

    li:nth-child(2) {
        transition: .3s .2s ease-in-out;
    }

    li:nth-child(3) {
        transition: .3s .3s ease-in-out;
    }

    &.active {
        transform: translateY(0);
        transition: .5s ease-in-out;

        li {
            opacity: 1;
            top: 0;
        }

        li:nth-child(1) {
            transition: .5s .2s ease-in-out;
        }

        li:nth-child(2) {
            transition: .5s .4s ease-in-out;
        }

        li:nth-child(3) {
            transition: .5s .6s ease-in-out;
        }
    }

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        text-align: center;
        font-size: 10rem;
        height: 100%;

        a {
            text-decoration: none;
            color: white;
            margin: 0 0 10px 0;
            display: block;
        }
    }
}