.page__loading {    
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100%;
    background: black;
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;

    .preloader {
        width: 100px;
        height: 100px;
    }
}