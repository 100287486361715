.page__work {
    nav {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 99;

        ul {
            font-size: calc(3vw + 10vh);

            li {
                line-height: 0.8em;
                margin-bottom: .3em;
                letter-spacing: -0.05em;
            }
        }
    }
}