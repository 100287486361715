$regular: 'Basis Grotesque Arabic Pro';
$medium: 'Basis Grotesque Arabic Pro Md';
$breakpoint: 900px;
$mobile: 640px;

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-size: calc(100vw / 1920 * 20);
}

html, body {
    height: 100%;
    font-family: $regular;
}

html{
    margin:0;
    padding:0;
    overflow: hidden;
}

body{
    position: absolute;
    width:100%;
    height:100%;
    overflow: auto;
}

body {
    margin:0;
    padding:0;
    background:#000;
    color:#fff;
    overflow: hidden;
    font-weight: normal;
    font-style: normal;

    &.loading {
        .page {
            opacity: 0;
            visibility: hidden;
        }

        .navigation {
            opacity: 0;
            visibility: hidden;
        }
    }

    @media screen and (max-device-width: $mobile)
        and (orientation: landscape) {
        .site-wrapper {
            display: none;
        }
    }
}

.site-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out;

    &.is-open {
        transform: translateX(-100%);
        transition: transform 0.5s ease-in-out;
    }

    .site-wrapper_inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
    }

    .mobile-nav {
        width: 100%;
        background: black;
        height: 100%;
        transform: translateX(100%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 6vh;

        a {
            display: block;
            margin: 0 0 0.5em 0;
            color: white;
            text-decoration: none;
        }
    }
}

.mobile {
    opacity: 0;
    visibility: hidden;
    height: 0;
    display: none;

    @media only screen and (max-width: $breakpoint) {
        opacity: 1;
        visibility: visible;
        height: auto;
        display: block;
    }
}

#webgl {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
}

#app {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
}

.page {
    // opacity: 0;
    // visibility: hidden;
}

#planes {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    pointer-events: none;
}

main {
    height: 100%;
    pointer-events: none;
    position: relative;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 3;

    // >section {
    //     opacity: 0;
    //     visibility: hidden;
    // }
}

.wipe {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
    background: #fff;
    transition: .5s ease;
    z-index: 88;

    &.in {
        width: 100%;
    }

    &.out {
        transform: translateX(100%);
    }
}

.hidden {
    display: none;
}
