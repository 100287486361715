.page__home {
    min-height: 100%;
    pointer-events: all;
    min-height: 100vh;
    background-image: -moz-element('#repeat');
    background-repeat: repeat-y;
    background-position: center;

    
    .artistImagePlane {
        width: 600px;
        height: 600px;
        position: absolute;
        top: 50px;
        left: 100px;
        z-index: 99;
        background-color: black;
        transform: scale(0) skew(32deg, 32deg);
        transition: transform 0.3s ease;
        overflow:hidden;
        cursor: pointer;

        @media only screen and (max-width: $mobile) {
            width: 380px;
            height: 380px;
        }
        
        >div {
            height: 100%;
            width: 100%;
            background-size: cover;
            transform: scale(1.3) translateX(70px) translateY(30px);
        }

        &.in {
            transform: scale(1) skew(0deg, 0deg);
            transition: transform 0.3s ease;
        }
    }


    .repetition {
        height: 0px; 
        overflow: hidden; 
        }       
    
    h3 {
        text-align: center;
        font-weight: 300;
        font-size: 50px;
    }

    #marquees {
        font-size: 5vh;
        height: 100vh;
        padding-top: 100px;
    }
    
    #marquees div {
        display: inline-block;
        white-space: nowrap;
        margin: 0 0 5px 0;
        width: 150%;
        position: relative;
        left: -10%;

        a {
            display: inline;

            &:hover {
                color: #6a6a6a;
                cursor: pointer;
            }
        }
    }        

    .artist-scroll-wrapper {
        margin-top: 120px;

        @media only screen and (max-width: $breakpoint) {
            overflow: hidden;
            margin-top: 100px;
        }
        
        &.in {
            opacity: 1;
            transition: opacity .3s ease;
        }

        
    }

    .artist-scroll {
        font-size: 4.8vh;
        font-family: $regular;
        user-select: none;

        a {
            pointer-events: all;
            color: white;
            text-decoration: none;

            &:hover, &:active, &:focus {
                color: #6a6a6a;
                cursor: pointer;
            }
        }
    }

    .diagonal-marquee {
        transform: rotate(45deg);
    }

    .marquee3k__copy {
        padding-right: 10px;
        box-sizing: border-box;
        margin-bottom: 3px;
    }

}