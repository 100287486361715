@font-face {
    font-family: 'Basis Grotesque Arabic Pro Md';
    src: url('../_fonts/BasisGrotesqueArabicPro-Medium.woff2') format('woff2'),
        url('../_fonts/BasisGrotesqueArabicPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Basis Grotesque Arabic Pro';
    src: url('../_fonts/BasisGrotesqueArabicPro-Regular.woff2') format('woff2'),
        url('../_fonts/BasisGrotesqueArabicPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularBook';
    src: url(../_fonts/circular/circular-book.woff2);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CircularBold';
    src: url(../_fonts/circular/circular-bold.woff2);
    font-weight: normal;
    font-style: normal;
}