.page__contact {

  $fontSize: 14px;
  
  height: 100%;

  .backgroundSwipe {
      background: white;
      height: 100%;
      width: 0%;
      transition: 0.5s ease;

      &.in {
          width: 100%;
      }
  }

  .contactImagePlane_inner {
    background-image: url(../../static/images/crowd.jpg);
    background-position: center;
  }

  .contactImagePlane {
      width: 600px;
      height: 600px;
      position: absolute;
      top: 50px;
      left: 100px;
      z-index: 99;
      background-color: black;
      transform: scale(0) skew(32deg, 32deg);
      transition: transform 0.3s ease;
      overflow:hidden;
      pointer-events: all;

      @media only screen and (max-width: $mobile) {
          display: none;
      }
      
      >div {
          height: 100%;
          width: 100%;
          background-size: cover;
      }

      &.in {
          transform: scale(1) skew(0deg, 0deg);
          transition: transform 0.3s ease;
      }
  }

  .page__contact_contactDetailsPanel {
      position: absolute;
      user-select: none;
      width: 600px;
      max-width: 800px;
      height: 600px;
      background: rgba(255,255,255, 1);
      pointer-events: all;
      left: 50%;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0;
      overflow: hidden;
      z-index: 99;
      // border: 1px solid #ccc;
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: $mobile) {
        //   left: 0;
        //   right: 0;
        //   bottom: 0;
        //   width: 100%;
        //   height: 100%;
        width: 380px;
        height: 500px;
      }
      
      .page__contact_contactDetailsPanel__header {
          margin-bottom: 0;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
      }

      .page__contact_contactDetailsPanel__inner {
          padding: 25px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
      }

      .territory-name {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          
          label {
              color: black;
              line-height: 18px;
              font-size: $fontSize;

              
          }
      }

      .socials {
          line-height: 18px;

          li {
              color: black;
              font-size: $fontSize;

              a {
                display: inline-block;
                width: 30px;
                height: 30px;
                transition: opacity .3s ease;
                cursor: pointer;
                
                &:hover {
                  opacity: .5;
                  transition: opacity .3s ease;
                }
              }
          }
      }

      .bio {
          height: 250px;
          overflow: hidden;
          padding-right: 15px;
          margin: 0;

          div.bio-content {
              color: black;
              font-size: $fontSize;
              line-height: 18px;
              column-count: 2;
              break-inside: avoid-column;

              >div {
                margin: 0 0 15px 0;
              }

              a {
                color: #333;
                text-decoration: none;
                font-size: 20px;
                cursor: pointer;

                @media only screen and (max-width: $mobile) {
                    font-size: 16px;
                }
              }

             
          }
      }

      h2, h3 {
          color: black;
          font-size: 42px;
          font-family: $medium;
          letter-spacing: -0.03em;

          @media only screen and (max-width: $mobile) {
            font-size: 25px;
        }
      }

      h3 {
        font-size: 20px;
        margin-bottom: 5px;

        @media only screen and (max-width: $mobile) {
            font-size: 16px;
        }
      }

      

      a.btn-enquire {
          display: inline-block;
          background: black;
          padding: 15px 20px;
          border-radius: 20px;
          color: white;
          text-decoration: none;
          margin: 20px 0 0 0;
          text-transform: uppercase;
          font-size: 14px;
          line-height: 0;

          &:hover {
              background: #ccc;
              color: black;
          }
      }

      .bio-wrapper {
          position: relative;
          height: 250px;
      }
  }

  #outer {
      font-size: 50vh;
      height: 100vh;
      padding-top: 100px;
      position: absolute;
      top: -40px;
      left: 0;
      right: 0;
      bottom: 0;
      color: #000;

      .loop:last-child {
          position: relative;
          top: -0.3em;
      }
  }
  
  #outer div {
      display: inline-block;
      text-transform: uppercase;
      letter-spacing: -0.04em;
  }

  @mixin buildArtistLoop {
      @for $i from 1 through 2 {
          .loop:nth-child(#{$i}) {
              white-space: nowrap;
              margin: 3px 0;
              
              .content {
                  user-select: none;
                  font-family: 'CircularBook';

                  @if $i % 2 == 0 {
                      transform: translateX(30%);
                  }
              

                  div {
                      display: block !important;
                  }
                  
              }
          }
      }
  }

  .inner {
      @include buildArtistLoop;
  }


  .artistNameTop {
      height: 50%;
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      position: absolute;
      top: 60px;
      left: 0;
      margin: 0;
      color: black;
      font-family: $medium;
      font-size: 50vh;
      text-transform: uppercase;
  }

  .artistNameBottom {
      height: 50%;
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 0;
      margin: 0;
      color: black;
      font-family: $medium;
      font-size: 50vh;
      text-transform: uppercase;
  }

}
