.scrollbar {
    position: absolute;
    right: -10px;
    bottom: 0;
    top: 0;
    z-index: 10;
    padding: 0 0;
    pointer-events: all;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &.scrollable {
        visibility: visible;
        opacity: 1;
    }
}
    
.scrollbar .inner {
    height: 100%;
    position: relative;
}

.scrollbar .thumb {
    min-height: 18px;
    width: 10px;
    background-color: black;
    position: absolute;
    right: 0px;
    cursor: grab;
    z-index: 2;
}

.scrollbar .track {
    width: 1px;
    height: 100%;
    background: #e0e0e0;
    transform: translateX(-5px);
    position: relative;
    z-index: 1;
}

html.lenis {
    height: auto;
  }
  
.lenis.lenis-smooth {
    scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}

.lenis.lenis-stopped {
    overflow: hidden;
}

.lenis.lenis-scrolling iframe {
    pointer-events: none;
} 