.animator {
    @import './typeout';
    @import './buttons';
    
    opacity: 0;
    visibility: hidden;
    user-select: none;
}

.animator.show {
    opacity: 1;
    visibility: visible;
}