.page__artists {

  @media only screen and (max-width: $mobile) {
    #webgl {
      display: none;
    }
  }

  .scrollbar {
    right: 10px;

    @media only screen and (max-width: $mobile) {
      display: none;
    }
    
    .thumb {
      background: white;
    }
  }

  .page__artists__panel {
    position: absolute;
    background: black;
    width: 60%;
    padding: 0 2em;
    bottom: 2em;
    right: 0;
    height: 70%;
    z-index: 10;

    @media only screen and (max-width: $mobile) {
      width: 100%;
      height: 100%;
      top: 100px;
      padding-left: 20px;
    }
    
    .scroller-artists {
      height: 100%;
      overflow: hidden;
    }

    .panel_inner {
      display: grid;
      grid-gap: 10px; 
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

      @media only screen and (max-width: $mobile) {
        grid-gap: 20px;
        padding: 0 0 0 20px;
        grid-template-columns: none;

        h2 {
          font-size: 40px !important;
        }

        a {
          font-size: 20px !important;
        }
      }
      
    }

   

    .page__artists__panel_box {
        pointer-events: all;
        background-color: #000;
        color: #fff;
        border-radius: 5px;
        padding: 20px;
        font-size: 12px;
        user-select: none;

        a {
            display: block;
            color: #fff;
            text-decoration: none;
            margin: 0 0 .2em 0;

            &:hover {
                color: #ccc;
            }
        }

        h2 {
            font-size: 20px;
            margin: 0 0 .5em 0;
        }
    }
  }
}
